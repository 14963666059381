import * as React from 'react';
import * as Constants from '../../common/constants';

export interface Props {}

const Privacy: React.StatelessComponent<Props> = (props: Props) => {
  return (
    <main id="content" role="main" className="layout__main">
      <p>
        <a href="/">&laquo; Take me back to saving the nation</a>
      </p>
      <p>
        Last updated: <strong>September 18, 2017</strong>
      </p>
      <p>
        {' '}
        5calls.org ("us", "we", or "our") operates the 5 Calls website (the
        "Service").
      </p>

      <p>
        This page informs you of our policies regarding the collection, use and
        disclosure of Personal Information when you use our Service.
      </p>

      <p>
        We will not use or share your information with anyone except as
        described in this Privacy Policy.
      </p>

      <p>
        <strong>
          We use your Personal Information for providing and improving the
          Service. By using the Service, you agree to the collection and use of
          information in accordance with this policy.
        </strong>{' '}
        Unless otherwise defined in this Privacy Policy, terms used in this
        Privacy Policy have the same meanings as in our Terms and Conditions.
      </p>

      <p>
        <strong>Information Collection And Use</strong>
      </p>

      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information may include, but is
        not limited to, your email address, name, phone number, or other
        information ("Personal Information").
      </p>

      <p>
        We collect this information for the purpose of providing the Service,
        identifying and communicating with you, responding to your
        requests/inquiries, and improving our services.
      </p>

      <p>
        <strong>Log Data</strong>
      </p>

      <p>
        We collect information that your browser sends whenever you visit our
        Service ("Log Data"). This Log Data may include information such as your
        computer’s Internet Protocol ("IP") address, browser type, browser
        version, the pages of our Service that you visit, the time and date of
        your visit, the time spent on those pages and other statistics.
      </p>

      <p>
        In addition, we may use third party services such as Google Analytics
        that collect, monitor and analyze this type of information in order to
        increase our Service’s functionality. These third party service
        providers have their own privacy policies addressing how they use such
        information.
      </p>

      <p>
        When you access the Service by or through a mobile device, we may
        collect certain information automatically, including, but not limited
        to, the type of mobile device you use, your mobile device’s unique
        device ID, the IP address of your mobile device, your mobile operating
        system, the type of mobile Internet browser you use, your location
        information and other statistics.
      </p>

      <p>
        Please see the section regarding Location Information below regarding
        the use of your location information and your options.
      </p>

      <p>
        <strong>Location information</strong>
      </p>

      <p>
        We may use and store information about your location depending on the
        permissions you have set on your device. We use this information to
        provide features of our Service, to improve and customize our Service.
        You can enable or disable location services when you use our Service at
        anytime, through your mobile device settings.
      </p>

      <p>
        <strong>Cookies</strong>
      </p>

      <p>
        Cookies are files with a small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and transferred to your device. We use cookies to collect
        information in order to improve our services for you.
      </p>

      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. The Help feature on most browsers provide
        information on how to accept cookies, disable cookies or to notify you
        when receiving a new cookie.
      </p>

      <p>
        If you do not accept cookies, you may not be able to use some features
        of our Service and we recommend that you leave them turned on.
      </p>

      <p>
        <strong>Service Providers</strong>
      </p>

      <p>
        We may employ third party companies and individuals to facilitate our
        Service, to provide the Service on our behalf, to perform
        Service-related services and/or to assist us in analyzing how our
        Service is used.
      </p>

      <p>
        These third parties have access to your Personal Information only to
        perform specific tasks on our behalf and are obligated not to disclose
        or use your information for any other purpose.
      </p>

      <p>
        Specifically, we use OneSignal to help us send notifications. You can
        find their{' '}
        <a href="https://onesignal.com/privacy_policy">
          privacy policy located here
        </a>
        .
      </p>

      <p>
        <strong>Security</strong>
      </p>

      <p>
        The security of your Personal Information is important to us, and we
        strive to implement and maintain reasonable, commercially acceptable
        security procedures and practices appropriate to the nature of the
        information we store, in order to protect it from unauthorized access,
        destruction, use, modification, or disclosure.
      </p>

      <p>
        However, please be aware that no method of transmission over the
        internet, or method of electronic storage is 100% secure and we are
        unable to guarantee the absolute security of the Personal Information we
        have collected from you.
      </p>

      <p>
        <strong>Links To Other Sites</strong>
      </p>

      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party’s site. We strongly advise you to review the Privacy Policy
        of every site you visit.
      </p>

      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <p>
        <strong>Children’s Privacy</strong>
      </p>

      <p>
        Only persons age 18 or older have permission to access our Service. Our
        Service does not address anyone under the age of 13 ("Children").
      </p>

      <p>
        We do not knowingly collect personally identifiable information from
        children under 13. If you are a parent or guardian and you learn that
        your Children have provided us with Personal Information, please contact
        us. If we become aware that we have collected Personal Information from
        a children under age 13 without verification of parental consent, we
        take steps to remove that information from our servers.
      </p>

      <p>
        <strong>Changes To This Privacy Policy</strong>
      </p>

      <p>
        This Privacy Policy is effective as of September 18, 2017 and will
        remain in effect except with respect to any changes in its provisions in
        the future, which will be in effect immediately after being posted on
        this page.
      </p>

      <p>
        We reserve the right to update or change our Privacy Policy at any time
        and you should check this Privacy Policy periodically. Your continued
        use of the Service after we post any modifications to the Privacy Policy
        on this page will constitute your acknowledgment of the modifications
        and your consent to abide and be bound by the modified Privacy Policy.
      </p>

      <p>
        If we make any material changes to this Privacy Policy, we will notify
        you either through the email address you have provided us, or by placing
        a prominent notice on our website.
      </p>

      <p>
        <strong>Contact Us</strong>
      </p>

      <p>
        If you have any questions about this Privacy Policy, please{' '}
        <a href={`mailto:${Constants.contact.email}`}>contact us</a>.
      </p>
    </main>
  );
};

export default Privacy;
